import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import photoQR from "../../img/qrCodeContainer.jpg";

/* Components */
import HeaderContent from "../../components/HeaderContent";
import ButtonGoBack from "../../components/ButtonGoBack";
import LoaderHandler from "../../components/loader/LoaderHandler";

/* Constants */
import {
  TABLE_UPDATE_RESET,
  TABLE_DETAILS_RESET,
  TABLE_DELETE_RESET,
} from "../../constants/tableConstants";

/* Actions */
import { listTableDetails, updateTable } from "../../actions/tableActions";
import ReactToPrint from "react-to-print";

const TableEditScreen = ({ history, match }) => {
  const tableId = parseInt(match.params.id);
  const refQR = useRef(null);

  const [name, setName] = useState("");
  const [occupied, setOccupied] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  //table details state
  const tableDetails = useSelector((state) => state.tableDetails);
  const { loading, error, table } = tableDetails;

  //table update state
  const tableUpdate = useSelector((state) => state.tableUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = tableUpdate;

  const restaurentId = useSelector(
    (state) => state.restaurantsReducer.selectedRestaurants.id
  );

  const [loadingPrint, setLoadingPrint] = useState(false)

  useEffect(() => {
    if(!loading) {
        setTimeout(() => {
            setLoadingPrint(true)
        },1000)
        
    }
  }, [loading])
  

  useEffect(() => {
    //after update redirect to users
    if (successUpdate) {
      dispatch({ type: TABLE_UPDATE_RESET });
      dispatch({ type: TABLE_DETAILS_RESET });
      dispatch({ type: TABLE_DELETE_RESET });

      history.push("/table");
    }

    //load table data
    if (table) {
      if (!table.name || table.id !== tableId) {
        dispatch(listTableDetails(tableId));
      } else {
        //set states
        setName(table.name);
        setOccupied(table.occupied);
      }
    }
  }, [dispatch, history, tableId, table, successUpdate]);

  
  const renderQR = () => (
    <div>
      <div style={{ position: "relative" }} ref={refQR}>
        <img src={photoQR} style={{ height: "1004px" }} />
        <div style={{ position: "absolute", top: "615px", left: "180px" }}>
          <QRCode
            title={name}
            size={100}
            style={{ height: "auto", maxWidth: "230px", width: "230px" }}
            value={`https://tables.cocotunisia.tn/${restaurentId}/${tableId}`}
            viewBox={`0 0 100 100`}
          />
        </div>
      </div>
    </div>
  );
  console.log(refQR);
  return (
    <>
      {/* Content Header (Page header) */}
      <HeaderContent name={"Tables"} />

      {/* Main content */}

      <section className="content">
        <div className="container-fluid">
          <ButtonGoBack history={history} />
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">QR CODE : {name}</h3>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <LoaderHandler loading={loadingUpdate} error={errorUpdate} />
                  <LoaderHandler
                    loading={loading}
                    error={error}
                    render={renderQR}
                  />
                  {loadingPrint && !loading && (
                    <ReactToPrint
                      content={() => refQR.current}
                      documentTitle={`QR-${name}`}
                      // onAfterPrint={handleAfterPrint}
                      // onBeforeGetContent={handleOnBeforeGetContent}
                      // onBeforePrint={handleBeforePrint}
                      removeAfterPrint
                      trigger={() => {
                        return <button>imprimer</button>;
                      }}
                    />
                  )}
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </>
  );
};

export default TableEditScreen;
