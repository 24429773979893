export const ORDER_STATISTICS_REQUEST = "ORDER_STATISTICS_REQUEST";
export const ORDER_STATISTICS_SUCCESS = "ORDER_STATISTICS_SUCCESS";
export const ORDER_STATISTICS_FAIL = "ORDER_STATISTICS_FAIL";
export const ORDER_STATISTICS_RESET = "ORDER_STATISTICS_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_LIST_RESET = "ORDER_LIST_RESET";

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";
export const ORDER_UPDATE_RESET = "ORDER_UPDATE_RESET";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

export const GET_ONGOING_ORDERS = "GET_ONGOING_ORDERS"
export const GET_ONGOING_ORDERS_SUCCESS = "GET_ONGOING_ORDERS_SUCCESS"
export const GET_ONGOING_ORDER_FAIL = "GET_ONGOING_ORDER_FAIL"
export const ADD_ONGOING_ORDER = 'ADD_ONGOING_ORDER'
export const ONGOING_IS_READY = 'ONGOING_IS_READY'
