import React from 'react';

const NotAuthorizedScreen = () => {
    return ( 
        <h1>
            No Authorized
        </h1>
     );
}
 
export default NotAuthorizedScreen;