import React from 'react';

const Footer = () => {
    return ( 
        <footer className="main-footer">
            <strong>Copyright © 2023 <a href="https://dashausmarketing.tn">DAS HAUS MARKETING</a>.</strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0.0
            </div>
        </footer>
     );
}
 
export default Footer;