// socketService.js
import { io } from 'socket.io-client';

let socket = null;
const URL = 'https://backend-commandes.cocotunisia.tn'
// const URL = 'http://localhost:5001'
export const connectSocket = (token) => {
  if (!socket) {
    socket = io(URL, {
      auth: {
        authorization: token,
      },
    });

    socket.on('connect', () => {
      console.log('Connected to socket server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from socket server');
    });
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => socket;
