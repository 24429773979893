import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

/* Components */
import HeaderContent from "../../components/HeaderContent";
import Input from "../../components/form/Input";
import ModalButton from "../../components/ModalButton";
import Modal from "react-modal";
import Checkbox from "../../components/form/Checkbox";
import DataTableLoader from "../../components/loader/DataTableLoader";
import Search from "../../components/Search";
import Pagination from "../../components/Pagination";
import LoaderHandler from "../../components/loader/LoaderHandler";

/* Actions */
import { listUsers, register } from "../../actions/userActions";

/* Styles */
import { modalStyles } from "../../utils/styles";
import { roleLists } from "../../actions/roleActions";
import { restaurantsList } from "../../actions/restaurantActions";
import Select from "../../components/Select";

const UserScreen = ({ history }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [pageNumber, setPageNumber] = useState(1);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [newRestaurantName, setNewRestaurantName] = useState("");
    const [isNewRestaurant, setIsNewRestaurant] = useState(false);
    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [restaurant, setRestaurant] = useState({});
    const [role, setRole] = useState({});

    const dispatch = useDispatch();

    const userList = useSelector((state) => state.userList);
    const { loading, error, users, page, pages } = userList;

    const userLogin = useSelector((state) => state.userLogin);
    const rolesState = useSelector(state => state.roleList)
    const restaurantState = useSelector(state => state.restaurantsReducer)
    const { userInfo } = userLogin;
    const { restaurants } = restaurantState
    const { roles } = rolesState

    const userRegister = useSelector((state) => state.userRegister);
    const {
        loading: createLoading,
        success: createSuccess,
        error: createError,
    } = userRegister;

    useEffect(() => {
        if (userInfo && userInfo.role.name === 'super admin') {
            Promise.all([dispatch(listUsers(keyword, pageNumber)), dispatch(roleLists()), dispatch(restaurantsList())])
        }
        if (createSuccess) {
            setFirstName("");
            setLastName("");
            setPassword("");
            setEmail("");
            setIsNewRestaurant(false)
            setNewRestaurantName('')
            setRole('')
            setPhone('')
            setRestaurant('')
            setRestaurantAddress('')

            setModalIsOpen(false);
        }
    }, [dispatch, userInfo, pageNumber, keyword, history, createSuccess]);

    const handleSubmit = (e) => {
        e.preventDefault();

        let errorsCheck = {};
        if (!firstName) {
            errorsCheck.firstName = "first name is required";
        }
        if (!lastName) {
            errorsCheck.lastName = "last name is required";
        }
        if (!password) {
            errorsCheck.password = "Password is required";
        }
        if (!phone) {
            errorsCheck.phone = "Phone Number is required";
        }

        if (!email) {
            errorsCheck.email = "Email is required";
        }
        if (!restaurant && !newRestaurantName) {
            errorsCheck.newRestaurantName = "restaurant is required";
        }
        if (!restaurant && newRestaurantName && !restaurantAddress) {
            errorsCheck.newRestaurantName = "restaurant address is required";
        }
        if (!role) {
            errorsCheck.role = "role is required";
        }

        if (Object.keys(errorsCheck).length > 0) {
            setErrors(errorsCheck);
        } else {
            setErrors({});
        }

        if (Object.keys(errorsCheck).length === 0) {
            console.log(restaurant)
            console.log(role)
            const user = {
                userName: `${firstName}_${lastName}`,
                firstName,
                lastName,
                phone,
                email: email,
                password: password,
                roleId: role,
            };
            if (isNewRestaurant) {
                user.newRestaurantName = newRestaurantName
                user.newRestaurantAddress = restaurantAddress
            } else {
                user.restaurantId = restaurant
            }

            dispatch(register(user));
        }
    };
    const renderTable = () => (
        <table className="table table-hover text-nowrap">
            <thead>
                <tr>
                    <th className="d-none d-sm-table-cell">ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th className="d-none d-sm-table-cell">Photo</th>
                    <th className="d-none d-sm-table-cell">Admin</th>
                    <th className="d-none d-sm-table-cell">Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td className="d-none d-sm-table-cell">{user.id}</td>
                        <td>{`${user.firstName} ${user.lastName}`} </td>
                        <td>{user.email}</td>
                        <td className="d-none d-sm-table-cell">
                            <img
                                src={
                                    user.image
                                        ? user.image
                                        : "/dist/img/user2-160x160.jpg"
                                }
                                style={{
                                    height: "2em",
                                }}
                                className="img-circle elevation-2"
                                alt="User"
                            />
                        </td>
                        <td className="d-none d-sm-table-cell">
                            {user.role.name === 'super admin' ? (
                                <h4 className="text-success">
                                    <i className="fas fa-check"></i>
                                </h4>
                            ) : (
                                <h4 className="text-danger">
                                    <i className="far fa-times-circle"></i>
                                </h4>
                            )}
                        </td>
                        <td className="d-none d-sm-table-cell">
                            {user.createdAt.slice(0, 10)}
                        </td>
                        <td>
                            {user.role.name !== 'super admin' ? (
                                ""
                            ) : (
                                <Link
                                    to={`/user/${user.id}/edit`}
                                    className="btn btn-warning btn-lg"
                                >
                                    Edit
                                </Link>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderModalCreateUser = () => (
        <>
            <ModalButton
                modal={modalIsOpen}
                setModal={setModalIsOpen}
                classes={"btn-success btn-lg mb-2"}
            />
            <Modal
                style={modalStyles}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <h2>Create Form</h2>
                <LoaderHandler loading={createLoading} error={createError} />
                <form onSubmit={handleSubmit}>
                    <Input
                        name={"firstName"}
                        label={"First Name"}
                        type={"text"}
                        data={firstName}
                        setData={setFirstName}
                        errors={errors}
                    />
                    <Input
                        name={"lastName"}
                        label={"Last Name"}
                        type={"text"}
                        data={lastName}
                        setData={setLastName}
                        errors={errors}
                    />
                    <Input
                        name={"email"}
                        type={"email"}
                        data={email}
                        setData={setEmail}
                        errors={errors}
                    />
                    <Input
                        name={"password"}
                        type={"password"}
                        data={password}
                        setData={setPassword}
                        errors={errors}
                    />
                    <Input
                        label={'Phone Number'}
                        name={'phone'}
                        type={"number"}
                        data={phone}
                        setData={setPhone}
                        errors={errors}
                    />
                    <Checkbox
                        name={'new Restaurant'}
                        data={isNewRestaurant}
                        setData={setIsNewRestaurant}
                    />


                    {isNewRestaurant ?
                        <>
                            <Input

                                label={"Restaurant Name"}
                                name={"newRestaurantName"}
                                type={"text"}
                                data={newRestaurantName}
                                setData={setNewRestaurantName}
                                errors={errors}
                            />
                            <Input
                                name={"restaurantAddress"}
                                label={"Restaurant address"}
                                type={"text"}
                                data={restaurantAddress}
                                setData={setRestaurantAddress}
                                errors={errors}
                            />
                        </>

                        :
                        <div>
                            <label>Restaurant</label>
                            <Select
                                items={restaurants}
                                data={restaurant}
                                setData={setRestaurant}

                            />
                        </div>}

                    <div style={{ marginTop: '10px' }}>
                        <label>Role</label>
                        <Select
                            items={roles}
                            data={role}
                            setData={setRole}
                        />
                    </div>



                    <hr />
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                    <ModalButton
                        modal={modalIsOpen}
                        setModal={setModalIsOpen}
                        classes={"btn-danger float-right"}
                    />
                </form>
            </Modal>
        </>
    );

    return (
        <>
            {/* Content Header (Page header) */}
            <HeaderContent name={"Users"} />
            {/* Main content */}

            <section className="content">
                <div className="container-fluid">
                    {renderModalCreateUser()}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Users table</h3>
                                    <div className="card-tools">
                                        <Search
                                            keyword={keyword}
                                            setKeyword={setKeyword}
                                            setPage={setPageNumber}
                                        />
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body table-responsive p-0">
                                    <LoaderHandler
                                        loading={loading}
                                        error={error}
                                        loader={<DataTableLoader />}
                                        render={renderTable}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                            <Pagination
                                page={page}
                                pages={pages}
                                setPage={setPageNumber}
                            />
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </>
    );
};

export default UserScreen;
