import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Menu = ({ history }) => {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            redirectTo();
        }
    }, [dispatch, userInfo]);

    const redirectTo = () => {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { referrer: "/" },
                }}
            />
        );
    };
    const adminSection = [
        { name: 'Users', link: '/user', icon: 'nav-icon fas fa-users', roles: ['super admin',] },
        { name: 'Active Orders', link: '/active', icon: 'nav-icon fas fa-bell', roles: ['super admin', 'manager'] },
        { name: 'Delivery', link: '/delivery', icon: 'nav-icon fas fa-truck', roles: ['super admin', 'manager'] },
        { name: 'Orders', link: '/order', icon: 'nav-icon far fa-clipboard', roles: ['super admin', 'manager'] },
        { name: 'Ongoing Orders', link: '/ongoing', icon: 'nav-icon far fa-clipboard', roles: ['super admin', 'manager', 'kitchen'] },
    ]
    const managementSection = [
        { name: 'Categories', link: '/category', icon: 'nav-icon fas fa-list-alt', roles: ['super admin', 'manager'] },
        { name: 'Products', link: '/product', icon: 'nav-icon fas fa-hamburger', roles: ['super admin', 'manager'] },
        { name: 'Clients', link: '/client', icon: 'nav-icon fas fa-users', roles: ['super admin', 'manager'] },
        { name: 'Tables', link: '/table', icon: 'nav-icon fas fa-border-all', roles: ['super admin', 'manager'] },
    ]
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <Link to={"/dashboard"} className="brand-link">
                <img
                    src="/logo.png"
                    alt="AdminLTE Logo"
                    className="brand-image"
                    style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">Gestion de Tables</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={userInfo ? userInfo.image : "/avatar.png"}
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : ""}
                        </Link>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />{" "}
                                <p> Dashboard</p>
                            </Link>
                        </li>
                        {userInfo?.role?.name === 'super admin' && <li className="nav-header">ADMIN</li>}
                        {adminSection.map((menu, index) => (
                            <>
                                {menu.roles.includes(userInfo.role.name) && <li key={`admin_${index}`} className="nav-item">

                                    <Link to={menu.link} className="nav-link">
                                        <i className={menu.icon} />{" "}
                                        <p> {menu.name}</p>
                                    </Link>
                                </li>}

                            </>

                        ))}

                        <li className="nav-header">MANAGEMENT</li>
                        {managementSection.map((menu, i) => (
                            <>
                                {menu.roles.includes(userInfo.role.name) && <li key={`management_${i}`} className="nav-item">
                                    <Link to={menu.link} className="nav-link">
                                        <i className={menu.icon} />{" "}
                                        <p> {menu.name}</p>
                                    </Link>
                                </li>}
                            </>

                        ))}
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside >
    );
};

export default Menu;
