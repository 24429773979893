import React from 'react';
import Loader from './../Loader';
import Message from './../Message';


const Select = ({ id = 1, setData, items, loading, error, name = 'Categories', selected }) => {
  const selectedItem = (item) => {
    if (selected) {
      return item.id === selected
    }
    return item.id === id
  }
  return (
    <div className="form-group">
      <label htmlFor="categories">{name}</label>
      {loading
        ? <Loader variable={loading} />
        : error
          ? <Message message={error} color={'danger'} />
          : (
            <select className="form-control" id="categories" onChange={(e) => setData(e.target.value)} >
              {items.map((item) => (
                <option key={item.id} value={item.id} selected={selectedItem(item)} >{item.name}</option>
              ))}
            </select>

          )


      }

    </div>
  );
}

export default Select;