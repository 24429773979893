import { GET_RESTAURANTS, SET_RESTAURANT } from "../constants/restaurantsConstants";

export const restaurantsReducer = (
    state = {
        selectedRestaurants: {},
        restaurants: []
    },
    action
) => {
    switch (action.type) {
        case SET_RESTAURANT:
            //set user info into local storage
            localStorage.setItem("selectedRestaurants", JSON.stringify(action.payload));
            return { selectedRestaurants: action.payload };
        case GET_RESTAURANTS:
            //set user info into local storage

            return { ...state, restaurants: action.payload };
        default:
            return state;
    }
};


