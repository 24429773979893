import React, { forwardRef } from 'react';

const Ticket = forwardRef((props, ref) => {
  const totalPrice = props.order?.products?.reduce((acc, product) => acc + (product.price * product.order_products.quantity), 0);

  return (
    <div ref={ref} className="container pt-5">
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="ticket">
            <h2 className="text-center mb-4">{props.order?.Restaurant?.name}</h2>
            <div className="d-flex justify-content-between mb-3">
              <p>Date: {new Date().toLocaleDateString()}</p>
              <p>Heure: {new Date().toLocaleTimeString()}</p>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Produit</th>
                  <th>Quantité</th>
                  <th>Prix</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {props.order?.products?.map((product, index) => (
                  <tr key={index}>
                    <td>{product.name}</td>
                    <td>{product.order_products.quantity}</td>
                    <td>{product.price} TND</td>
                    <td>{(product.price * product.order_products.quantity)} TND</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <div className="d-flex justify-content-between">
              <p className="font-weight-bold">Total:</p>
              <p className="font-weight-bold">{totalPrice||0} TND </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Ticket;
