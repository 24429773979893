import axios from "./initAxios";
import { GET_RESTAURANTS, GET_RESTAURANTS_FAIL } from "../constants/restaurantsConstants";

export const restaurantsList = () => async (dispatch, getState) => {
    try {

        //get user from state
        const {
            userLogin: { userInfo },
        } = getState();

        //headers
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        //api call to get product
        const { data } = await axios.get(`/api/restaurants/all`, config);
        dispatch({
            type: GET_RESTAURANTS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_RESTAURANTS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};