import axios from "./initAxios";
import { ROLE_LIST_FAIL, ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS } from "../constants/roleConstants";

export const roleLists = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ROLE_LIST_REQUEST });

        //get user from state
        const {
            userLogin: { userInfo },
        } = getState();

        //headers
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        //api call to get product
        const { data } = await axios.get(`/api/roles`, config);
        dispatch({
            type: ROLE_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ROLE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};