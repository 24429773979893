import axios from "./initAxios";
import {
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAIL,
    ORDER_CREATE_REQUEST,
    ORDER_CREATE_SUCCESS,
    ORDER_CREATE_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_UPDATE_REQUEST,
    ORDER_UPDATE_SUCCESS,
    ORDER_UPDATE_FAIL,
    ORDER_DELETE_REQUEST,
    ORDER_DELETE_SUCCESS,
    ORDER_DELETE_FAIL,
    ORDER_STATISTICS_REQUEST,
    ORDER_STATISTICS_SUCCESS,
    ORDER_STATISTICS_FAIL,
    GET_ONGOING_ORDERS,
    GET_ONGOING_ORDERS_SUCCESS,
    ONGOING_IS_READY,
} from "../constants/orderConstants";

//get all sales
export const getStatistics = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_STATISTICS_REQUEST,
        });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }
        } = getState();
        //headers
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
            id: selectedRestaurants.id
        };

        //get all sales
        const { data } = await axios.get(`/api/orders/statistics?restaurantId=${selectedRestaurants.id}`, config);

        dispatch({
            type: ORDER_STATISTICS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_STATISTICS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//get all orders with pagination
export const listOrders = (options) => async (dispatch, getState) => {
    const { keyword, pageNumber, delivery } = options;
    try {
        dispatch({
            type: ORDER_LIST_REQUEST,
        });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();

        //headers
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };

        //get all orders
        const { data } = await axios.get(
            `/api/orders/?keyword=${keyword}&pageNumber=${pageNumber}${delivery ? "&delivery=true" : ""
            }&restaurantId=${selectedRestaurants.id}`,
            config
        );

        dispatch({
            type: ORDER_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//create a order
export const createOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_CREATE_REQUEST,
        });

        //get order from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();

        //headers
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };

        //create order
        const { data } = await axios.post(`/api/orders/?restaurantId=${selectedRestaurants.id}`, order, config);
        dispatch({
            type: ORDER_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//get order details
export const listOrderDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: ORDER_DETAILS_REQUEST });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();

        //headers
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };

        //api call to get ORDER
        const { data } = await axios.get(`/api/orders/${id}?restaurantId=${selectedRestaurants.id}`, config);
        dispatch({
            type: ORDER_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//update a order
export const updateOrder = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_UPDATE_REQUEST,
        });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();
        //headers
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };

        //update order
        const { data } = await axios.put(
            `/api/orders/${order.id}?restaurantId=${selectedRestaurants.id}`,
            order,
            config
        );
        dispatch({
            type: ORDER_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//update a order
export const updateOrderToPaid = (order) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_UPDATE_REQUEST,
        });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();
        //headers
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };
        //update order
        const { data } = await axios.post(
            `/api/orders/${order.id}/pay?restaurantId=${selectedRestaurants.id}`,
            order,
            config
        );
        dispatch({
            type: ORDER_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ORDER_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

//delete order
export const deleteOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORDER_DELETE_REQUEST,
        });

        //get user from state
        const {
            userLogin: { userInfo },
            restaurantsReducer: { selectedRestaurants }

        } = getState();
        //headers
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: { restaurantId: selectedRestaurants.id },
        };

        //api call to delete order
        await axios.delete(`/api/orders/${id}?restaurantId=${selectedRestaurants.id}`, config);
        dispatch({
            type: ORDER_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ORDER_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getOngoingOrders = (isReady = false) => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_ONGOING_ORDERS })
        const {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                authorization: `Bearer ${userInfo.token}`,
            }
        };
        const { data } = await axios.get(`/api/orders/ongoing?isReady=${isReady}`, config)
        dispatch({
            type: GET_ONGOING_ORDERS_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_ONGOING_ORDERS,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}

export const onGoingOrdersReady = (id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo },
        } = getState();
        console.log("dd",userInfo.token)
        const config = {
            headers: {
                authorization: `Bearer ${userInfo.token}`,
            }
        };
        await axios.put(`/api/orders/ready/${id}`,{},config)
        dispatch({
            type: ONGOING_IS_READY,
            payload: id,
        });

    } catch (error) {
        dispatch({
            type: GET_ONGOING_ORDERS,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}
