import React, { useEffect, useState } from 'react'
import HeaderContent from '../../components/HeaderContent';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_ONGOING_ORDER, GET_ONGOING_ORDERS } from '../../constants/orderConstants';
import { getOngoingOrders, onGoingOrdersReady } from '../../actions/orderActions';
import DataTableLoader from '../../components/loader/DataTableLoader';
import LoaderHandler from '../../components/loader/LoaderHandler';
import { connectSocket, getSocket } from '../../socket';
const OngoingOrders = () => {
    const dispatch = useDispatch()
    const { orders, loading, errors } = useSelector((state) => state.ongoingOrders)
    const [orderList, setOrderList] = useState([])
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    let socket


    useEffect(() => {
        dispatch(getOngoingOrders())
        setOrderList(orders)
        getSocketInstance()
        if (socket) {
            socket.on('newOrder', (data) => {
                const newOrder = {
                    id: data.id,
                    orderId: data.order.id,
                    restaurantId: data.order.restaurantId,
                    isReady: data.isReady,
                    products: data.order.products.map(el => ({
                        name: el.name,
                        id: el.id,
                        ongoingorder_products: {
                            quantity: el.order_products.quantity
                        }
                    }
                    )),
                    order: {
                        note: data.order.note,
                        id: data.order.id,
                        table: {
                            id: data.order.table.id,
                            name: data.order.table.name
                        },
                    },
                    createdAt: data.order.createdAt
                }
                dispatch({ type: ADD_ONGOING_ORDER, payload: newOrder })
            })
        }

    }, [socket])

    const getOrders = (isReady) => {
        dispatch(getOngoingOrders(isReady))
    }

    const getSocketInstance = () => {
        const instance = getSocket()
        if (!instance) {
            connectSocket(userInfo.token)
            socket = getSocket()
        }
        socket = instance
    }



    const renderButtons = () => {
        return (
            <div>
                <button className="btn btn-success btn-lg" onClick={() => getOrders(false)}>
                    Ongoing Orders
                </button>
                <button className="btn btn-success btn-lg  ml-20" onClick={() => getOrders(true)}>
                    Completed Orders
                </button>
            </div>
        )
    }

    const markAsReady = (id) => {
        dispatch(onGoingOrdersReady(id))
    }

    const renderOrdersList = () => (
        <table className="table table-hover text-nowrap">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Products</th>
                    <th>Note</th>
                    <th>Table</th>
                    <th className="d-none d-sm-table-cell">Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order.id}>
                        <td>{order.order.id}</td>
                        <td>
                            <ul>
                                {order.products.map((product, key) => (
                                    <li key={`product_${key}`}>{`${product.ongoingorder_products.quantity} ${product.name}`}</li>
                                ))}
                            </ul>
                        </td>
                        <td>{order.note}</td>
                        <td>{order.order.table.name}</td>
                        <td className="d-none d-sm-table-cell">
                            {new Date(order.createdAt).toLocaleString('fr', { dateStyle: 'short', timeStyle: 'short' })}
                        </td>
                        <td>
                            {!order.isReady && <button
                                className="btn btn-warning btn-lg"
                                onClick={() => markAsReady(order.id)}
                            >
                                Ready
                            </button>}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const renderOrders = () => {
        return (
            <div className="card ">
                {/* /.card-header */}
                <div className="card-body table-responsive p-0">
                    <LoaderHandler
                        loading={loading}
                        error={errors}
                        loader={DataTableLoader()}
                        render={renderOrdersList}
                    />
                </div>
                {/* /.card-body */}
            </div>
        )
    }
    return (<div>
        <>
            <HeaderContent name={"Ongoing Orders"} />

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {renderButtons()}
                            <hr />
                            {renderOrders()}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </>
    </div>);
}

export default OngoingOrders;